
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      containsActiveLink: false,
      submenuFrozen: false,
    }
  },

  computed: {
    navItemLevel1() {
      return this.data
    },
  },

  watch: {
    $route() {
      this.checkIfContainsActiveLink()

      this.submenuFrozen = true

      if (document.activeElement) {
        document.activeElement.blur()
      }

      setTimeout(() => {
        this.submenuFrozen = false
      }, 1000)
    },
  },

  mounted() {
    this.checkIfContainsActiveLink()
  },

  methods: {
    open() {
      this.$nuxt.$emit('nav-drodown-opened', this.$el)
    },

    close() {
      this.$nuxt.$emit('nav-drodown-closed', this.$el)
    },

    handleKeyDown(e) {
      if (e.key === 'Escape') {
        document.activeElement.blur()
        this.close()
      }
    },

    navItemText(page) {
      if (page.text) return page.text

      if (page.heros) {
        return page.heros[0].text
      }

      return null
    },

    shouldRenderLink(navItemLevel2) {
      return (
        navItemLevel2.children.length === 0 &&
        this.navItemText(navItemLevel2.page)
      )
    },

    checkIfContainsActiveLink() {
      this.$nextTick(() => {
        this.containsActiveLink = !!this.$el.querySelector('.nuxt-link-active')
      })
    },
  },
}
